export const Users_REQUEST = 'Users_REQUEST';
export const Users_SUCCESS = 'Users_SUCCESS';
export const Users_FAILURE = 'Users_FAILURE';
export const UsersDefined_REQUEST = 'UsersDefined_REQUEST';
export const UsersDefined_SUCCESS = 'UsersDefined_SUCCESS';
export const UsersDefined_FAILURE = 'UsersDefined_FAILURE';

export const EnableUser_REQUEST = 'EnableUser_REQUEST';
export const EnableUser_SUCCESS = 'EnableUser_SUCCESS';
export const EnableUser_FAILURE = 'EnableUser_FAILURE';

export const DisableUser_REQUEST = 'DisableUser_REQUEST';
export const DisableUser_SUCCESS = 'DisableUser_SUCCESS';
export const DisableUser_FAILURE = 'DisableUser_FAILURE';
export const ORGCODE_REQUEST = 'ORGCODE_REQUEST';
export const ORGCODE_SUCCESS = 'ORGCODE_SUCCESS';
export const ORGCODE_FAILURE = 'ORGCODE_FAILURE';

export const RESETPASSWORD_REQUEST = 'RESETPASSWORD_REQUEST';
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS';
export const RESETPASSWORD_FAILURE = 'RESETPASSWORD_FAILURE';

export const RESETDEVICE_REQUEST = 'RESETDEVICE_REQUEST';
export const RESETDEVICE_SUCCESS = 'RESETDEVICE_SUCCESS';
export const RESETDEVICE_FAILURE = 'RESETDEVICE_FAILURE';

export const CHECKPORTALACCESS_REQUEST = 'CHECKPORTALACCESS_REQUEST';
export const CHECKPORTALACCESS_SUCCESS = 'CHECKPORTALACCESS_SUCCESS';
export const CHECKPORTALACCESS_FAILURE = 'CHECKPORTALACCESS_FAILURE';

export const UPDATEACCESS_REQUEST = 'UPDATEACCESS_REQUEST';
export const UPDATEACCESS_SUCCESS = 'UPDATEACCESS_SUCCESS';
export const UPDATEACCESS_FAILURE = 'UPDATEACCESS_FAILURE';

export const ADDMANAGER_REQUEST = 'ADDMANAGER_REQUEST';
export const ADDMANAGER_SUCCESS = 'ADDMANAGER_SUCCESS';
export const ADDMANAGER_FAILURE = 'ADDMANAGER_FAILURE';
export const RESETENABLED_SUCCESS = 'RESETENABLED_SUCCESS';
export const RESETDISABLED_SUCCESS = 'RESETDISABLED_SUCCESS';

export const USERSEARCH_REQUEST = 'USERSEARCH_REQUEST';
export const USERSEARCH_SUCCESS = 'USERSEARCH_SUCCESS';
export const USERSEARCH_FAILURE = 'USERSEARCH_FAILURE';
