import React from 'react';
import styled, {css} from 'styled-components';
import COLORS from '../../constants/style/colors';
import {Select} from 'antd';

const StageWaterfall = ({
  data,
  defaultValue,
  handleSelectClick,
  options,
  onClick,
}) => {
  const dataKeys = Object.keys(data);
  const handleChange = (x) => {
    handleSelectClick(x);
  };
  return (
    <Container>
      <StageContainer>
        {dataKeys.map((key, index) => {
          const lastIndex = index === dataKeys.length - 1;
          return (
            <StageRowAndLinesContainer key={key}>
              <StageRowContainer onClick={() => onClick(data[key].code)}>
                <StageLabel>{data[key].title}</StageLabel>
                <StageTextContainer>
                  <StageValueContainer
                    background={data[key].background}
                    width={`${100 - 14 * index}%`}>
                    <StageValue>{data[key].value}</StageValue>
                  </StageValueContainer>
                </StageTextContainer>
              </StageRowContainer>
              <LinesContainer>
                {(index === 2 || lastIndex) && (
                  <HorizontalLine index={index} lastIndex={lastIndex} />
                )}
                {(index === 2 || index === 3 || index === 4 || lastIndex) && (
                  <VerticalLine index={index} lastIndex={lastIndex} />
                )}
                {index === 4 && (
                  <div>
                    <HorizontalLine index={index} />
                    <CurrentMonthText>Closing Month</CurrentMonthText>
                    <MonthDropDown
                      defaultValue={defaultValue}
                      onChange={handleChange}
                      options={options}
                      style={{borderColor: 'orange'}}
                    />
                  </div>
                )}
              </LinesContainer>
            </StageRowAndLinesContainer>
          );
        })}
      </StageContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 60%;
`;
const StageContainer = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
`;
const StageRowAndLinesContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;
const StageRowContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  padding: 10px 0;
  color: #fff;
`;
const StageLabel = styled.span`
  text-align: right;
  padding-right: 2rem;
  color: #374151;
  width: 120px;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: ${COLORS.brandColor.current};
  }
`;
const StageTextContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
const StageValueContainer = styled.div`
  height: 42px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.background};
  width: ${(props) => props.width};

  &:hover {
    color: ${COLORS.brandColor.current};
    background-color: #fff;
  }
`;
const StageValue = styled.span`
  font-size: 18px;
  font-weight: 600;
`;
const LinesContainer = styled.div`
  display: flex;
  width: 156px;
  height: 100%;
`;
const HorizontalLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: #374151;

  ${(props) =>
    (props.index === 2 || props.lastIndex) &&
    css`
      align-self: center;
      width: 36px;
    `}
  ${(props) =>
    props.index === 4 &&
    css`
      marginleft: -2px;
      width: 12px;
    `}
`;
const VerticalLine = styled.div`
  width: 2px;
  margin-left: -2px;
  background-color: #374151;

  ${(props) =>
    props.index === 2 &&
    css`
      align-self: flex-end;
      height: 50%;
    `}
  ${(props) =>
    (props.index === 3 || props.index === 4) &&
    css`
      align-self: flex-end;
      height: 100%;
      margin-left: 34px;
    `}
  ${(props) =>
    props.lastIndex &&
    css`
      height: 50%;
    `}
`;
const CurrentMonthText = styled.span`
  color: #374151;
  font-size: 14px;
  margin-top: -40px;
  font-weight: 500;
  margin-left: 28px;
  position: absolute;
  cursor: pointer;

  &:hover {
    color: ${COLORS.brandColor.current};
  }
`;
const MonthDropDown = styled(Select)`
  height: 42px;
  width: 130px;
  font-weight: 600;
  // color: ${COLORS.brandColor.current};
  // border-color: ${COLORS.brandColor.current};
  color: #374151;
  font-size: 14px;
  margin-top: -16px;
  margin-left: 18px;
  position: absolute;
  cursor: pointer;
`;

export default StageWaterfall;
