import React, {useEffect, useState} from 'react';
import {
  Modal,
  Form,
  Collapse,
  Input,
  Checkbox,
  Radio,
  Button,
  Select,
  Table,
  DatePicker,
  AutoComplete,
  Space,
  message,
} from 'antd';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {getDropdownValues} from '../../modules/dropdowns/action';
import {getDropdownsData} from '../../modules/dropdowns/selector';
import {getMyAccounts} from '../../modules/accounts/action';
import {getMyAccountsData} from '../../modules/accounts/selectors';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getContactsByMCode} from '../../modules/contacts/action';
import {getContactsDataByMCode} from '../../modules/contacts/selectors';
import {getContacts} from '../../modules/contacts/action';
import {contactsData} from '../../modules/contacts/selectors';
import {
  updateOpportunity,
  calculateOpportunityStage,
} from '../../modules/opportunities/action';
import {
  updateOpportunityDataSelector,
  calculateOpportunityStageSelector,
} from '../../modules/opportunities/selectors';

const {Panel} = Collapse;
const {Option} = Select;

const OpportunityModalEditForm = ({
  visible,
  onCancel,
  onCreate,
  onSubmit,
  data,
}) => {
  const {TextArea} = Input;
  const dispatch = useDispatch();
  const opportunityRequestType = useSelector(updateOpportunityDataSelector);
  const [saveClicked, setSaveClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [keyAccount, setKeyAccount] = useState(data?.keyAccount);
  const [form, setForm] = useState({
    accountName: data ? data?.accountName : '',
    accountMCode: data ? data?.accountMCode : '',
    contactMcode: data ? data?.contactMcode : '',
    model: data ? data?.model : '',
    variant: data ? data?.variant : '',
    opportunityType: data ? data?.opportunityType : '',
    quantity: data ? data?.quantity : '',
    isBulkDeal: data ? data?.isBulkDeal : false,
    isTender: data ? data?.isTender : false,
    tenderReferenceNumber: data ? data?.tenderReferenceNumber : '',
    tenderDate:
      data && data?.tenderDate
        ? moment(data.tenderDate)._d
        : moment(new Date())._d,
    startDate:
      data && data?.startDate
        ? moment(data.startDate)._d
        : moment(new Date())._d,
    estimatedClosingDate:
      data && data?.estimatedClosingDate
        ? moment(data.estimatedClosingDate)._d
        : moment(new Date())._d,
    createdDate: data ? data?.createDate : '',
    insurer: data ? data?.insurer : '',
    transporter: data ? data?.transporter : '',
    remarks: data ? data?.remarks : '',
    opportunityStatus: data ? data?.opportunityStatus : '',

    financier: data ? data?.financier : '',
    financierName: data ? data?.financierName : '',
    financierContact: data ? data.financierContact : '',
    financierContactName: data ? data?.financierContactName : '',
    customerType: data ? data?.customerType : '',
    applicationType: data ? data?.application : '',
    usageType: data ? data?.usage : '',
    originType: data ? data?.origin : '',
    originSource: data ? data?.originSource : '',
    project: data ? data?.project : '',
    site: data ? data?.site : '',
    payoutAgent: data ? data?.payoutAgent : '',
    withAttachment: data
      ? data?.isAttachmentAvailable === 'Yes'
        ? true
        : false
      : false,
    isQuotationRequired: data ? data?.isQuotationRequired : false,
    isDemonstrationRequired: data ? data?.isDemonstrationRequired : false,

    competitorCode: '',
    competitorModel: '',
    competitorValue: '',
    isLostToCompetitor: '',
    competitorRemarks: '',

    transactionalStage: data ? data?.opportunityStages?.transactional : [],
    nonTransactionalStage: data
      ? data?.opportunityStages?.nonTransactional
      : [],
    calculatedOppurtunityStage: data
      ? data?.opportunityStages?.opportunityStage
      : '',

    reason1: data ? data?.opportunityReason?.reason1 : '',
    reason2: data ? data?.opportunityReason?.reason2 : '',
    reason3: data ? data?.opportunityReason?.reason3 : '',
    contactDistrictName: data ? data?.contactDistrictName : '',
  });

  const [addedCompetitor, setAddedCompetitor] = useState(
    data ? data?.competitors : [],
  );
  useEffect(() => {
    if (visible) {
      setForm({
        accountName: data ? data?.accountName : '',
        accountMCode: data ? data?.accountMCode : '',
        contactMcode: data ? data?.contactMcode : '',
        model: data ? data?.model : '',
        variant: data ? data?.variant : '',
        opportunityType: data ? data?.opportunityType : '',
        quantity: data ? data?.quantity : '',
        isBulkDeal: data ? data?.isBulkDeal : false,
        isTender: data ? data?.isTender : false,
        tenderReferenceNumber: data ? data?.tenderReferenceNumber : '',
        tenderDate:
          data && data?.tenderDate
            ? moment(data.tenderDate)._d
            : moment(new Date())._d,
        startDate:
          data && data?.startDate
            ? moment(data.startDate)._d
            : moment(new Date())._d,
        estimatedClosingDate:
          data && data?.estimatedClosingDate
            ? moment(data.estimatedClosingDate)._d
            : moment(new Date())._d,
        createdDate: data ? data?.createDate : '',
        insurer: data ? data?.insurer : '',
        transporter: data ? data?.transporter : '',
        remarks: data ? data?.remarks : '',
        opportunityStatus: data ? data?.opportunityStatus : '',

        financier: data ? data?.financier : '',
        financierName: data ? data?.financierName : '',
        financierContact: data ? data.financierContact : '',
        financierContactName: data ? data?.financierContactName : '',
        customerType: data ? data?.customerType : '',
        applicationType: data ? data?.application : '',
        usageType: data ? data?.usage : '',
        originType: data ? data?.origin : '',
        originSource: data ? data?.originSource : '',
        project: data ? data?.project : '',
        site: data ? data?.site : '',
        payoutAgent: data ? data?.payoutAgent : '',
        withAttachment: data
          ? data?.isAttachmentAvailable === 'Yes'
            ? true
            : false
          : false,
        isQuotationRequired: data ? data?.isQuotationRequired : false,
        isDemonstrationRequired: data ? data?.isDemonstrationRequired : false,

        competitorCode: '',
        competitorModel: '',
        competitorValue: '',
        isLostToCompetitor: '',
        competitorRemarks: '',

        transactionalStage: data ? data?.opportunityStages?.transactional : [],
        nonTransactionalStage: data
          ? data?.opportunityStages?.nonTransactional
          : [],
        calculatedOppurtunityStage: data
          ? data?.opportunityStages?.opportunityStage
          : '',

        reason1: data ? data?.opportunityReason?.reason1 : '',
        reason2: data ? data?.opportunityReason?.reason2 : '',
        reason3: data ? data?.opportunityReason?.reason3 : '',
        contactDistrictName: data ? data?.contactDistrictName : '',
      });
      setKeyAccount(data ? data?.keyAccount : false);
      setAddedCompetitor(data ? data?.competitors : []);
    }
  }, [visible, data]);

  const onSearchSelect = (value) => {
    //console.log('search:', value);
  };

  const handleFormFieldChange = (value, name) => {
    switch (name) {
      case 'reason1':
      case 'reason2':
      case 'reason3':
        setForm({
          ...form,
          [name]: value,
        });
        break;

      case 'accountName':
        const splittedAccountValue = value.split('^');
        const accountName = splittedAccountValue[0];
        const accountMCode = splittedAccountValue[1];
        setForm({
          ...form,
          accountName,
          accountMCode,
        });
        break;

      case 'financierContactName':
        const splittedFContactValue = value.split('^');
        const financierContactName = splittedFContactValue[0];
        const financierContact = splittedFContactValue[1];
        setForm({
          ...form,
          financierContactName,
          financierContact,
        });
        break;

      case 'model':
        if (value !== data?.model) {
          setForm({
            ...form,
            [name]: value,
            variant: '',
          });
        } else {
          setForm({
            ...form,
            [name]: value,
          });
        }
        break;

      case 'transactionalStage':
        setForm({
          ...form,
          [name]: value,
          calculatedOppurtunityStage: calculatedOppStage?.payload?.name,
        });
        break;

      default:
        if (
          value?.target?.type
            ? value?.target?.type === 'text' || 'textarea || radio'
            : false
        ) {
          setForm({
            ...form,
            [name]: value?.target?.value,
          });
          break;
        } else
          setForm({
            ...form,
            [name]: value,
          });
        break;
    }
  };

  const handleFormSubmit = () => {
    setLoading(true);
    let payload = null;
    payload = {
      id: data?.id,
      mCode: data?.mCode,
      opportunityStatus: form.opportunityStatus ? form.opportunityStatus : '',
      opportunityReason: {
        reason1: form.reason1 ? form.reason1 : '',
        reason2: form.reason2 ? form.reason2 : '',
        reason3: form.reason3 ? form.reason3 : '',
      },
      opportunityStages: {
        transactional: form.transactionalStage ? form.transactionalStage : [],
        nonTransactional: form.nonTransactionalStage
          ? form.nonTransactionalStage
          : [],
        opportunityStage: calculatedOppStage
          ? calculatedOppStage?.payload?.name
          : form.calculatedOppurtunityStage,
      },
      competitors: addedCompetitor ? addedCompetitor : [],
      isAttachmentAvailable: form?.withAttachment === true ? 'Yes' : 'No',
      isDemonstrationRequired: form.isDemonstrationRequired,
      isQuotationRequired: form.isQuotationRequired,
      payoutAgent: form.payoutAgent ? form.payoutAgent : '',
      site: form.site ? form.site : '',
      project: form.project ? form.project : '',
      originSource: form.originSource ? form.originSource : '',
      origin: form.originType ? form.originType : '',
      usage: form.usageType ? form.usageType : '',
      application: form.applicationType ? form.applicationType : '',
      customerType: form.customerType ? form.customerType : '',
      financierContact: form.financier ? form.financierContact : '',
      financier: form.financier ? form.financier : '',
      remarks: form.remarks ? form.remarks : '',
      keyAccount: keyAccount,
      createdDate: form.createdDate,
      startDate: moment.utc(form.startDate).format(),
      estimatedClosingDate: moment.utc(form.estimatedClosingDate).format(),
      tenderDate: form.isTender
        ? moment.utc(form.tenderDate).format()
        : moment.utc(new Date()).format(),
      tenderReferenceNumber: form.isTender ? form.tenderReferenceNumber : '',
      isTender: form.isTender,
      isBulkDeal: form.isBulkDeal,
      quantity: form.quantity ? form.quantity : '',
      variant: form.variant ? form.variant : '',
      model: form.model ? form.model : '',
      contactMcode: form?.contactMcode ? form?.contactMcode : '',
      accountMCode: form.accountMCode ? form.accountMCode : '',
      opportunityType: form.opportunityType ? form.opportunityType : '',
      isClear: false,
      userId: data?.userId,
      RequiresSync: true,
      insurer: form.insurer,
      transporter: form.transporter,
    };

    if (
      form.accountName &&
      form.model &&
      form.variant &&
      form.estimatedClosingDate &&
      form.opportunityType
    ) {
      if (
        (payload.opportunityReason.reason1 === '' &&
          payload.opportunityReason.reason2 === '' &&
          payload.opportunityReason.reason3 === '') ||
        (payload.opportunityReason.reason1 === '' &&
          payload.opportunityReason.reason2 === '') ||
        (payload.opportunityReason.reason2 === '' &&
          payload.opportunityReason.reason3 === '') ||
        (payload.opportunityReason.reason3 === '' &&
          payload.opportunityReason.reason1 === '') ||
        (payload.opportunityReason.reason1 !==
          payload.opportunityReason.reason2 &&
          payload.opportunityReason.reason2 !==
            payload.opportunityReason.reason3 &&
          payload.opportunityReason.reason3 !==
            payload.opportunityReason.reason1)
      ) {
        dispatch(updateOpportunity(authUser.accessToken, payload)).finally(
          () => {
            setLoading(false); // Stop the loader after the request completes
          },
        );
      } else {
        setLoading(false);
        message.error(
          {
            content: 'Please select different reasons !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
      }
    } else {
      const errorInField = form.accountName
        ? form.model
          ? form.variant
            ? form.estimatedClosingDate
              ? form.opportunityType
                ? ''
                : 'select Opportunity Type'
              : 'select Closing Date'
            : 'select Variant'
          : 'select Model'
        : 'fill Account';
      const errorMessage = 'Please ' + errorInField;
      message.error(
        {
          content: errorMessage,
          className: 'custom-class',
          style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
        },
        5,
      );
    }
  };

  useEffect(() => {
    if (visible) {
      if (opportunityRequestType?.type === 'OpportunitiesUpdate_SUCCESS') {
        message.success(
          {
            content: 'Opportunity Updated Sucessfully !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
        onSubmit();
        onCancel();
      } else if (
        opportunityRequestType?.type === 'OpportunitiesUpdate_FAILURE'
      ) {
        message.error(
          {
            content: 'Opportunity Update Failed !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
      }
    }
  }, [opportunityRequestType]);

  const [accountNameOptions, setAccountNameOptions] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [
    financierContactNameOptions,
    setFinancierContactNameOptions,
  ] = useState([]);

  const authUser = useSelector(getAuthUserData);
  const calculatedOppStage = useSelector(calculateOpportunityStageSelector);

  useEffect(() => {
    dispatch(getDropdownValues());
  }, [dispatch]);
  const dropdownData = useSelector(getDropdownsData);

  useEffect(() => {
    dispatch(getMyAccounts(authUser.accessToken, 1, 3, form.accountName));
  }, [form.accountName, dispatch, authUser.accessToken]);
  const myAccountsData = useSelector(getMyAccountsData);
  useEffect(() => {
    if (myAccountsData && myAccountsData !== 'undefined') {
      let options = myAccountsData.map((item, index) => {
        return {value: item.name + '^' + item.mCode, label: item.name};
      });
      setAccountNameOptions(options);
      if (form?.accountMCode !== data?.accountMCode) {
        setKeyAccount(myAccountsData[0]?.isKeyAccount);
      } else {
        setKeyAccount(data?.keyAccount);
      }
    }
  }, [myAccountsData, data?.keyAccount, form.accountMCode, data?.accountMCode]);

  useEffect(() => {
    dispatch(
      getContactsByMCode(
        form?.accountMCode ? form.accountMCode : data?.accountMCode,
        authUser.accessToken,
      ),
    );
  }, [form.accountMCode, dispatch, authUser.accessToken]);
  const contactsDataByMCode = useSelector(getContactsDataByMCode);
  useEffect(() => {
    if (contactsDataByMCode) {
      let options = contactsDataByMCode.map((item, index) => {
        return {
          code: item?.mCode,
          name: item?.firstName + ' ' + item?.lastName,
        };
      });
      setContactOptions(options);
    }
  }, [contactsDataByMCode]);

  useEffect(() => {
    dispatch(
      getContacts(
        1,
        3,
        '',
        '',
        '',
        '',
        authUser.accessToken,
        form.financierContactName,
        '',
      ),
    );
  }, [dispatch, authUser.accessToken, form.financierContactName]);
  const myFinancierContactData = useSelector(contactsData);
  useEffect(() => {
    if (myFinancierContactData) {
      let options = myFinancierContactData.map((item, index) => {
        return {
          value: item?.firstName + ' ' + item?.lastName + '^' + item?.mCode,
          label: item?.firstName + ' ' + item?.lastName,
        };
      });
      setFinancierContactNameOptions(options);
    }
  }, [myFinancierContactData]);

  const competitorTableColumn = [
    {
      title: 'Competitor Name',
      dataIndex: 'competitorName',
      key: 'competitorName',
    },
    {
      title: 'Model',
      dataIndex: 'modelCode',
      key: 'modelCode',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Lost',
      dataIndex: 'isLostToCompetitor',
      key: 'isLostToCompetitor',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (record) => (
        <Space size="middle">
          <a onClick={() => onCompetitorDelete(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  const onAddCompetitorClick = () => {
    if (
      form?.competitorCode &&
      form?.competitorModel &&
      form?.competitorValue
    ) {
      setAddedCompetitor([
        ...addedCompetitor,
        {
          competitorCode: form?.competitorCode,
          modelCode: form?.competitorModel,
          value: form?.competitorValue,
          isLostToCompetitor: form?.isLostToCompetitor,
          remarks: form?.competitorRemarks,
        },
      ]);
    } else {
      const errorToShow = form?.competitorCode
        ? form?.competitorModel
          ? form?.competitorValue
            ? ''
            : 'Enter Value'
          : 'Select Model'
        : 'Select Competitor';
      message.error(
        {
          content: errorToShow,
          className: 'custom-class',
          style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
        },
        5,
      );
    }
  };

  const onCompetitorDelete = (record) => {
    setAddedCompetitor((prev) => {
      return prev.filter(
        (data) => data.competitorModel !== record.competitorModel,
      );
    });
  };

  const getVarients = (model) => {
    return dropdownData?.variants
      ?.filter((opt) => opt.model === model)
      .map((opt) => {
        return <Option value={opt?.code}>{opt?.name}</Option>;
      });
  };

  useEffect(() => {}, [addedCompetitor]);
  useEffect(() => {
    if (calculatedOppStage?.type === 'CALCULATEOPPORTUNITYSTAGE_SUCCESS') {
    } else if (
      calculatedOppStage?.type === 'CALCULATEOPPORTUNITYSTAGE_FAILURE'
    ) {
      message.error(
        {
          content: 'Select Transactional Stage again!',
          className: 'custom-class',
          style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
        },
        5,
      );
    }
  }, [calculatedOppStage]);
  return (
    <Modal
      destroyOnClose={true}
      footer={null}
      onCancel={onCancel}
      title="Opportunity Details"
      visible={visible}
      width={1000}>
      <Form>
        <table className="tableServiceDetails" width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td style={{float: 'right'}}>
                <Button
                  htmlType="submit"
                  loading={loading}
                  onClick={handleFormSubmit}
                  style={{
                    background: '#fd560eb5',
                    border: '#fd560eb5',
                    fontWeight: '550',
                  }}
                  type="primary">
                  Save
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Opportunity Basic Details" key="1">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader">
                    {' '}
                    Account Name <span style={{color: 'red'}}>*</span>
                  </td>

                  <td className="tdHeader"> Contact</td>
                  <td className="tdHeader">
                    {' '}
                    District <span style={{color: 'red'}}>*</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <AutoComplete
                      onChange={(data) =>
                        handleFormFieldChange(data, 'accountName')
                      }
                      onSelect={(value) =>
                        handleFormFieldChange(value, 'accountName')
                      }
                      options={accountNameOptions}
                      style={{width: '100%'}}
                      value={form.accountName}
                    />
                  </td>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={
                        form?.contactMcode
                          ? form?.contactMcode
                          : data?.contactMcode
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) =>
                        handleFormFieldChange(value, 'contactMcode')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a Contact"
                      showSearch
                      style={{width: '100%'}}>
                      {contactOptions?.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                  <td>
                    <Input
                      onChange={(data) =>
                        handleFormFieldChange(data, 'contactDistrictName')
                      }
                      value={form.contactDistrictName}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    {' '}
                    Model <span style={{color: 'red'}}>*</span>
                  </td>
                  <td className="tdHeader">
                    {' '}
                    Variant <span style={{color: 'red'}}>*</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Select
                      defaultValue={form.model}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) => handleFormFieldChange(data, 'model')}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a Model"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.opportunityModels?.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                  <td>
                    <Select
                      defaultValue={form.variant}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'variant')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a Variant"
                      showSearch
                      style={{width: '100%'}}>
                      {/* {dropdownData?.variants
                        ?.filter((opt) => opt.model === form.model)
                        .map((opt) => {
                          return <Option value={opt?.code}>{opt?.name}</Option>;
                        })} */}
                      {getVarients(form.model)}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    {' '}
                    Opportunity Type <span style={{color: 'red'}}>*</span>
                  </td>
                  <td className="tdHeader"> Quantity</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      defaultValue={form.opportunityType}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'opportunityType')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a Opportunity Type"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.opportunityTypes?.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                  <td>
                    <Input disabled value={form.quantity} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    <Checkbox
                      defaultChecked={form.isBulkDeal}
                      onChange={(data) =>
                        handleFormFieldChange(!form.isBulkDeal, 'isBulkDeal')
                      }>
                      {'  '}Bulk Deal{'  '}
                    </Checkbox>
                  </td>

                  <td className="tdHeader">
                    <Checkbox
                      defaultChecked={form.isTender}
                      onChange={(data) =>
                        handleFormFieldChange(!form.isTender, 'isTender')
                      }>
                      {'  '}Tender{'  '}
                    </Checkbox>
                  </td>
                </tr>
                <tr hidden={!form.isTender}>
                  <td className="tdHeader"> Tender Reference No.</td>
                  <td className="tdHeader"> Tender Date</td>
                </tr>
                <tr hidden={!form.isTender}>
                  <td>
                    <Input
                      onChange={(data) =>
                        handleFormFieldChange(data, 'tenderReferenceNumber')
                      }
                      value={form.tenderReferenceNumber}
                    />
                  </td>
                  <td>
                    <Space direction="vertical">
                      <DatePicker
                        clearIcon={null}
                        defaultValue={moment(form.tenderDate, 'DD/MM/YYYY')}
                        format={'DD/MM/YYYY'}
                        onChange={(data) =>
                          handleFormFieldChange(data, 'tenderDate')
                        }
                        style={{width: '100%', marginRight: '10px'}}
                        value={moment(form.tenderDate, 'DD/MM/YYYY')}
                      />
                    </Space>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Start Date</td>
                  <td className="tdHeader">
                    {' '}
                    Estimated Closing Date <span style={{color: 'red'}}>*</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Space direction="vertical">
                      <DatePicker
                        clearIcon={null}
                        defaultValue={moment(form.startDate, 'DD/MM/YYYY')}
                        format={'DD/MM/YYYY'}
                        onChange={(data) =>
                          handleFormFieldChange(data, 'startDate')
                        }
                        style={{width: '100%', marginRight: '10px'}}
                        value={moment(form.startDate, 'DD/MM/YYYY')}
                      />
                    </Space>
                  </td>
                  <td>
                    <Space direction="vertical">
                      <DatePicker
                        clearIcon={null}
                        defaultValue={moment(
                          form.estimatedClosingDate,
                          'DD/MM/YYYY',
                        )}
                        disabledDate={(current) => {
                          // Disable dates beyond 1 year from today
                          return current && current > moment().add(1, 'year');
                        }}
                        format={'DD/MM/YYYY'}
                        onChange={(data) =>
                          handleFormFieldChange(data, 'estimatedClosingDate')
                        }
                        style={{width: '100%', marginRight: '10px'}}
                        value={moment(form.estimatedClosingDate, 'DD/MM/YYYY')}
                      />
                    </Space>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Insurer</td>

                  <td className="tdHeader"> Transporter</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={(data) =>
                        handleFormFieldChange(data, 'insurer')
                      }
                      value={form.insurer}
                    />
                  </td>
                  <td>
                    <Input
                      onChange={(data) =>
                        handleFormFieldChange(data, 'transporter')
                      }
                      value={form.transporter}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Created Date</td>
                  <td className="tdHeader"> Remarks</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={moment(form.createdDate).format(
                        'DD/MM/YYYY hh:mm A',
                      )}
                    />
                  </td>
                  <td>
                    <TextArea
                      onChange={(data) =>
                        handleFormFieldChange(data, 'remarks')
                      }
                      rows={4}
                      value={form.remarks}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">Key Account</td>
                  <td className="tdHeader"> Opty Status</td>
                </tr>
                <tr>
                  <td>
                    <Checkbox checked={keyAccount} disabled={true} />
                  </td>
                  <td className="tdHeader">
                    <Radio.Group
                      defaultValue={form.opportunityStatus}
                      disabled={true}>
                      {dropdownData?.opportunityStatuses
                        .filter((opt) => opt.meta.readOnly === true)
                        .map((opt) => {
                          return <Radio value={opt?.code}>{opt?.name}</Radio>;
                        })}
                    </Radio.Group>
                    <Radio.Group
                      defaultValue={form.opportunityStatus}
                      onChange={(data) =>
                        handleFormFieldChange(data, 'opportunityStatus')
                      }>
                      {dropdownData?.opportunityStatuses
                        .filter((opt) => opt.meta.readOnly === false)
                        .map((opt) => {
                          return <Radio value={opt?.code}>{opt?.name}</Radio>;
                        })}
                    </Radio.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Opportunity Additional Details" key="2">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> Financier</td>
                  <td className="tdHeader"> Contact</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.financier}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'financier')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Financier"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.financiers.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                  <td>
                    <AutoComplete
                      onChange={(data) =>
                        handleFormFieldChange(data, 'financierContactName')
                      }
                      onSelect={(value) =>
                        handleFormFieldChange(value, 'financierContactName')
                      }
                      options={financierContactNameOptions}
                      style={{width: '100%', marginRight: '10px'}}
                      value={form.financierContactName}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Customer Type</td>
                  <td className="tdHeader">
                    Application Types<span style={{color: 'red'}}>*</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.customerType}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'customerType')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Customer Type"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.customerTypes.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.applicationType}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode="multiple"
                      onChange={(data) =>
                        handleFormFieldChange(data, 'applicationType')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Application Types"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.applicationTypes.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    {' '}
                    Usage<span style={{color: 'red'}}>*</span>
                  </td>
                  <td className="tdHeader">Origin</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.usageType}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'usageType')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Usage"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.usages.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.originType}
                      disabled={true}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'originType')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Origin"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.origins.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Origin Source</td>
                  <td className="tdHeader">Payout Agent</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={(data) =>
                        handleFormFieldChange(data, 'originSource')
                      }
                      value={form.originSource}
                    />
                  </td>
                  <td>
                    <Input
                      onChange={(data) =>
                        handleFormFieldChange(data, 'payoutAgent')
                      }
                      value={form.payoutAgent}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Project</td>
                  <td className="tdHeader"> Site</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={(data) =>
                        handleFormFieldChange(data, 'project')
                      }
                      value={form.project}
                    />
                  </td>
                  <td>
                    <Input
                      onChange={(data) => handleFormFieldChange(data, 'site')}
                      value={form.site}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    <Checkbox
                      checked={form.withAttachment}
                      onChange={(data) =>
                        handleFormFieldChange(
                          !form.withAttachment,
                          'withAttachment',
                        )
                      }>
                      With Attachment{' '}
                    </Checkbox>
                  </td>
                  <td className="tdHeader">
                    <Checkbox
                      checked={form.isQuotationRequired}
                      onChange={(data) =>
                        handleFormFieldChange(
                          !form.isQuotationRequired,
                          'isQuotationRequired',
                        )
                      }>
                      Required Proforma Invoice/Quotation{' '}
                    </Checkbox>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    <Checkbox
                      checked={form.isDemonstrationRequired}
                      onChange={(data) =>
                        handleFormFieldChange(
                          !form.isDemonstrationRequired,
                          'isDemonstrationRequired',
                        )
                      }>
                      Required Demonstration{' '}
                    </Checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Opportunity Competitor Details" key="3">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader">Competitor Name</td>
                  <td className="tdHeader"> Model</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.competitorCode}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'competitorCode')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Competitor"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.competitors.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.competitorModel}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'competitorModel')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Model"
                      showSearch
                      style={{width: '100%'}}>
                      {form?.competitorCode
                        ? dropdownData?.competitors
                            .filter(
                              (cpt) => cpt.code === form?.competitorCode,
                            )[0]
                            .models?.map((opt) => {
                              return (
                                <Option value={opt?.code}>{opt?.name}</Option>
                              );
                            })
                        : ''}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">Value</td>
                  <td className="tdHeader"> Lost to this competitor</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={(data) =>
                        handleFormFieldChange(data, 'competitorValue')
                      }
                      value={form.competitorValue}></Input>
                  </td>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.competitors?.isLostToCompetitor}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'isLostToCompetitor')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Lost"
                      showSearch
                      style={{width: '100%'}}>
                      <Option value="Yes">Yes</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">Remarks</td>
                  <td className="tdHeader"></td>
                </tr>
                <tr>
                  <td>
                    <TextArea
                      onChange={(data) =>
                        handleFormFieldChange(data, 'competitorRemarks')
                      }
                      rows={4}
                      value={form.competitorRemarks}
                    />
                  </td>
                  <td>
                    <div style={{textAlign: 'center'}}>
                      <Button onClick={onAddCompetitorClick} type="primary">
                        Add Competitor
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div hidden={!addedCompetitor?.length}>
              <Table
                columns={competitorTableColumn}
                dataSource={addedCompetitor}
              />
            </div>
          </Panel>
          <Panel header="Opportunity Stage Details" key="4">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader">Transactional Stages</td>
                  <td className="tdHeader">Non-Transactional Stages</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.transactionalStage}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode="multiple"
                      onChange={(data) => {
                        handleFormFieldChange(data, 'transactionalStage');
                        dispatch(
                          calculateOpportunityStage(authUser.accessToken, {
                            codes: [...data],
                          }),
                        );
                      }}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Transactional Stages"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.opportunityStages
                        ?.filter((opt) => opt.isTransactional === true)
                        .map((opt) => {
                          return <Option value={opt?.code}>{opt?.name}</Option>;
                        })}
                    </Select>
                  </td>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.nonTransactionalStage}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode="multiple"
                      onChange={(data) =>
                        handleFormFieldChange(data, 'nonTransactionalStage')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Non-Transactional Stages"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.opportunityStages
                        ?.filter((opt) => opt.isTransactional === false)
                        .map((opt) => {
                          return <Option value={opt?.code}>{opt?.name}</Option>;
                        })}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">Calculated Opportunity Stage</td>
                </tr>
                <tr>
                  <td>
                    <Radio.Group
                      disabled={true}
                      value={
                        calculatedOppStage
                          ? calculatedOppStage?.payload?.code
                          : dropdownData?.opportunityStageTypes?.filter(
                              (opt) =>
                                opt?.name === form?.calculatedOppurtunityStage,
                            )[0]?.code
                      }>
                      {dropdownData?.opportunityStageTypes?.map((opt) => {
                        return <Radio value={opt?.code}>{opt?.name}</Radio>;
                      })}
                    </Radio.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Opportunity Reasons Details" key="5">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader">Reasons 1</td>
                  <td className="tdHeader">Reasons 2</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.reason1}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'reason1')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Reason 1"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.reasons.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.reason2}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'reason2')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Reason 2"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.reasons.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">Reasons 3</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.reason3}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(data) =>
                        handleFormFieldChange(data, 'reason3')
                      }
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select Reason 3"
                      showSearch
                      style={{width: '100%'}}>
                      {dropdownData?.reasons.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};
export default OpportunityModalEditForm;
//?.filter((res) => !selectedReasons?.includes(res.code))
