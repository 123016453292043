import React, {useState, useEffect} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {Pagination, Table, Button} from 'antd';
import DataFilterBox from '../common/DataFilterBox';
import {useDispatch, useSelector} from 'react-redux';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getActivityType, getOrgActivities} from '../../modules/activity/action';
import {
  getActivitiesData,
  getActivitiesTypeData,
  fetchingActivities,
} from '../../modules/activity/selectors';
import {
  getDealerSalesRepresentativeFilterData,
  resetFilterData,
} from '../../modules/dashboard/actions';
import {RESET_SAlESREPRESENTATIVE} from '../../modules/dashboard/constants';
import {DealersSaleRepresentativeData} from '../../modules/dashboard/selectors';

import {activitiesColumns} from '../../data/activitiesColumns';
import {API_URL} from '../../constants/envVariables';
import {downloadCsv} from '../../utils/downloadCsv';
import {request} from '../../utils/HttpClient';
import getQueryString from '../../utils/getQueryString';
import HomeFilterBox from '../common/HomeFilter';
import {getTerritoryFilterData} from '../../modules/territoryFilter/selectors';
import {getTerritoryDetails} from '../../modules/territoryFilter/action';
import ActivityModalEditForm from '../common/ActivityModalEdit';
import moment from 'moment';

const Activity = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUserData);
  const [pageNumber, setPageNumber] = useState(1);
  const dataActivities = useSelector(getActivitiesData);
  const activitiesMasterData = useSelector(getActivitiesTypeData);
  const isFetchingData = useSelector(fetchingActivities);
  const [activityTypeFilter, setActivityTypeFilter] = useState('All');
  const [activityStatusFilter, setActivityStatusFilter] = useState('All');
  const [filterChanged, setfilterChanged] = useState(false);
  const territoryFilterData = useSelector(getTerritoryFilterData);
  const [zonesData, setZonesData] = useState([]);
  const [zones, setZones] = useState('Zone');
  const [branches, setBranches] = useState('Branch');
  const [dealerShips, setDealerShips] = useState('Dealership');
  const [salesRepresentatives, setSalesRepresentatives] = useState(
    'Sales Representative',
  );
  const [branchData, setBranchData] = useState([]);
  const [dealersData, setDealersData] = useState([]);
  const salesRepresentativesData = useSelector(DealersSaleRepresentativeData);
  const [fromDate, setFromDateFilter] = useState(
    moment().add(-7, 'day').toDate(),
  );
  const [toDate, setToDateFilter] = useState(moment(new Date()).toDate());
  const pageChanged = (page, pageSize) => {
    setPageNumber(page);
  };

  const [filteredAccountName, setFilteredAccountName] = useState('');
  const [filteredAccountMCode, setFilteredAccountMCode] = useState('');
  useEffect(() => {
    dispatch(getActivityType(authUser.accessToken));
    dispatch(
      getOrgActivities(
        authUser.accessToken,
        1,
        10,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ),
    );
  }, [dispatch, authUser.accessToken]);

  useEffect(() => {
    if (territoryFilterData) {
      setZonesData(territoryFilterData.zones);
    }
  }, [territoryFilterData]);

  useEffect(() => {
    dispatch(
      getOrgActivities(
        authUser.accessToken,
        pageNumber,
        10,
        activityTypeFilter === 'All' ? '' : activityTypeFilter,
        activityStatusFilter === 'All' ? '' : activityStatusFilter,
        zones === 'Zone' ? '' : zones,
        branches === 'Branch' ? '' : branches,
        dealerShips === 'Dealership' ? '' : dealerShips,
        salesRepresentatives === 'Sales Representative'
          ? ''
          : salesRepresentatives,
        moment(fromDate).format('YYYY-MM-DD'),
        moment(toDate).format('YYYY-MM-DD'),
        filteredAccountName !== '' ? filteredAccountName : '',
        filteredAccountMCode !== '' ? filteredAccountMCode : '',
      ),
    );
  }, [
    dispatch,
    pageNumber,
    activityStatusFilter,
    activityTypeFilter,
    zones,
    branches,
    dealerShips,
    salesRepresentatives,
    authUser.accessToken,
    fromDate,
    toDate,
    filteredAccountName,
    filteredAccountMCode,
  ]);
  const activityTypeChanged = (value) => {
    setActivityTypeFilter(value);
  };
  const activityStatusChanged = (value) => {
    setActivityStatusFilter(value);
  };
  const onAccountNameChange = ({target}) => {
    setFilteredAccountName(target.value.trim());
  };
  const onAccountMCodeChange = ({target}) => {
    setFilteredAccountMCode(target.value.trim());
  };
  const onResetDataClick = () => {
    setActivityStatusFilter('All');
    setActivityTypeFilter('All');
    setFromDateFilter(moment(new Date()).add(-7, 'day').toDate());
    setToDateFilter(moment(new Date()));
    setFilteredAccountName('');
    setFilteredAccountMCode('');
    pageChanged(1, 10);
  };
  const fromDateChanged = (value) => {
    setFromDateFilter(value._d);
  };
  const toDateChanged = (value) => {
    setToDateFilter(value._d);
  };
  //

  const onDownloadClick = () => {
    callApiAndDownloadCsv({
      ActivityTypeCode: activityTypeFilter === 'All' ? '' : activityTypeFilter,
      Status: activityStatusFilter === 'All' ? '' : activityStatusFilter,
      ZoneCode: zones === 'Zone' ? '' : zones,
      DealerCode: dealerShips === 'Dealership' ? '' : dealerShips,
      EmployeeCode:
        salesRepresentatives === 'Sales Representative'
          ? ''
          : salesRepresentatives,
      BranchCode: branches === 'Branch' ? '' : branches,
      PlannedFromDate: moment(fromDate).format('YYYY-MM-DD'),
      PlannedToDate: moment(toDate).format('YYYY-MM-DD'),
      Name: filteredAccountName !== '' ? filteredAccountName : '',
      AccountMCode: filteredAccountMCode !== '' ? filteredAccountMCode : '',
    });
  };

  const items = [
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: 'Activity Type',
      fieldType: 'Select',
      onChange: activityTypeChanged,
      value: activityTypeFilter,
      options: activitiesMasterData
        ? activitiesMasterData.data.activityTypes
        : null,
    },
    {
      fieldId: 4,
      fieldName: '',
      fieldTitle: 'Activity Status',
      fieldType: 'Select',
      onChange: activityStatusChanged,
      value: activityStatusFilter,
      options: activitiesMasterData
        ? activitiesMasterData.data.activityStatuses
        : null,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: 'From Date',
      fieldType: 'DatePicker',
      onChange: fromDateChanged,
      value: fromDate,
    },
    {
      fieldId: 4,
      fieldName: '',
      fieldTitle: 'To Date',
      fieldType: 'DatePicker',
      onChange: toDateChanged,
      value: toDate,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldType: 'Input',
      fieldTitle: 'Account Name',
      placeholderTitle: 'Enter Name',
      onChange: onAccountNameChange,
      value: filteredAccountName,
    },
    {
      fieldId: 4,
      fieldName: '',
      fieldTitle: 'Account MCode',
      fieldType: 'Input',
      placeholderTitle: 'Enter Code',
      onChange: onAccountMCodeChange,
      value: filteredAccountMCode,
    },
    {
      fieldId: 6,
      fieldName: 'Reset',
      fieldMargin: '15px',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onResetDataClick,
    },
    {
      fieldId: 7,
      fieldName: 'Download Data Dump',
      fieldTitle: '',
      fieldMargin: '15px',
      fieldType: 'Button',
      onClick: onDownloadClick,
    },
  ];

  const callApiAndDownloadCsv = async (filters) => {
    const filterQueryString = getQueryString(filters);
    const url = `${API_URL}/Activity/MyOrgActivitiesDownload?${filterQueryString}`;
    const data = await request(url, authUser.accessToken);

    if (data) {
      downloadCsv(data.payload, 'Activity_Details');
    }
  };

  useEffect(() => {
    dispatch(getTerritoryDetails(authUser.accessToken));
  }, [authUser.accessToken, dispatch]);

  const onZoneChange = (value) => {
    setZones(value);
    setfilterChanged(true);
    setBranchData(
      territoryFilterData.branches.filter((item) => item.zoneCode === value),
    );
    setBranches('Branch');
    setDealerShips('Dealership');
    setSalesRepresentatives('Sales Representative');
    dispatch(resetFilterData(RESET_SAlESREPRESENTATIVE));
    setDealersData([]);
  };

  const onBranchChange = (value) => {
    setBranches(value);
    setDealersData(
      territoryFilterData.dealerships.filter(
        (item) => item.branchCode === value,
      ),
    );
    setfilterChanged(true);
    dispatch(resetFilterData(RESET_SAlESREPRESENTATIVE));
    setDealerShips('Dealership');
    setSalesRepresentatives('Sales Representative');
  };
  const onSalesRepresentativesChange = (value) => {
    setSalesRepresentatives(value);
    setfilterChanged(true);
  };

  const onDealershipsChange = (value) => {
    dispatch(getDealerSalesRepresentativeFilterData(value));
    setDealerShips(value);
    setfilterChanged(true);
    setSalesRepresentatives('Sales Representative');
  };
  const onRefreshTerritoryClick = () => {
    setZones('Zone');
    setDealerShips('Dealership');
    setSalesRepresentatives('Sales Representative');
    setBranches('Branch');
    setBranchData([]);
    setDealersData([]);
    setfilterChanged(true);
    dispatch(resetFilterData(RESET_SAlESREPRESENTATIVE));
  };

  const itemsTerritory = [
    {
      fieldId: 7,
      fieldName: 'Territory',
      fieldTitle: '',
      fieldType: 'Span',
    },
    {
      fieldId: 1,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: zonesData ? zonesData : [],
      onChange: onZoneChange,
      value: zones,
      disable: true,
    },
    {
      fieldId: 2,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: branchData ? branchData : [],
      onChange: onBranchChange,
      value: branches,
      disable: true,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: dealersData ? dealersData : [],
      onChange: onDealershipsChange,
      value: dealerShips,
      disable: true,
    },
    {
      fieldId: 4,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      options: salesRepresentativesData ? salesRepresentativesData : [],
      onChange: onSalesRepresentativesChange,
      value: salesRepresentatives,
    },
    {
      fieldId: 5,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Col',
    },
    {
      fieldId: 6,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Refresh',
      onClick: onRefreshTerritoryClick,
    },
  ];

  const [modalData, setModalData] = useState([]);
  const [visible, setVisible] = useState(false);
  const getDetails = (e, record) => {
    setVisible(!visible);
    setModalData(record);
  };
  const updatedActivityCol = [
    ...activitiesColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'followUpCallId',
      width: '12%',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            getDetails(e, record);
          }}
          style={{
            background: '#fd560eb5',
            border: '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Details
        </Button>
      ),
    },
  ];

  const handleCreate = () => {};
  const handleModalSubmit = () => {
    dispatch(
      getOrgActivities(authUser.accessToken, 1, 10, '', '', '', '', '', ''),
    );
  };

  return (
    <MainLayout>
      <div className="contentLayout">
        <fieldset className="contentFieldSet">
          <legend>Activity Filter</legend>
          <HomeFilterBox items={itemsTerritory}></HomeFilterBox>
          <DataFilterBox items={items} title="Activity Filter"></DataFilterBox>
        </fieldset>
      </div>
      <div className="contentLayout">
        <span className="contentHeader">Activities</span>
        <Pagination
          current={pageNumber}
          defaultCurrent={1}
          onChange={pageChanged}
          style={{float: 'right'}}
          total={500}
        />
        <ActivityModalEditForm
          data={modalData}
          onCancel={() => setVisible(false)}
          onCreate={() => handleCreate()}
          onSubmit={() => handleModalSubmit()}
          visible={visible}
        />
        <Table
          columns={updatedActivityCol}
          dataSource={dataActivities ? dataActivities.data : null}
          loading={isFetchingData}
          pagination={false}
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};

export default Activity;
